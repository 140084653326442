(function($, window) {

    /**
     * Pre-loading UI Blocker
     *
     * Summary:
     * This script when included on any page will create a div that blocks all page interaction.
     *
     * How it works:
     * There are actually two UI blockers:
     *  - The dark background with the spinner, which shows up at 150ms idle
     *  - An invisible blocker which is ALWAYS present until all scripts have loaded.
     *
     * The use of the invisible blocker prevents users interacting with the page until JavaScript is ready.
     * The darkened loader with the spinner helps indicate if the page is loading slowly, and that interaction is blocked.
     *
     * To make this script work, it must be loaded directly after <body>.
     * An additional snippet must be injected directly before </body> (close body).
     *
     * The placement of the scripts is important. We use synchronous-script loading to ensure that all <script> sources
     * in the dom are loaded, and the final <script> on the page will call the UI-unblock code.
     *
     * The UI will be unblocked automatically after X seconds no matter what,
     * so that JS errors do not make a page permanently inaccessible.
     */
    $.extend($.blockUI.defaults, {
        message: '<div class="elmo-busy-spinner block-ui-loading"><span class="sr-only">Loading, please wait.</span></div>',
        css: {
            margin: 0,
            border: '0px none',
            padding: '0px',
            width: '100%',
            height: '1px',
            top: '50%',
            left: '0%',
            textAlign: 'center',
            color: '#000',
            backgroundColor: 'transparent'
        },
        blockMsgClass: 'unselectable',
        overlayCSS: {
            backgroundColor: 'rgba(0,0,0,0.25)',
            opacity: 1,
            cursor: 'wait'
        },
        onUnblock: function (element, options) {
            // ..
        },
        fadeIn: 1500,
        faceOut: 500
    });

    /**
     * UI Blocker
     */
    var blockUi = {

        // Becomes true when loading is successful
        loaded: false,

        // Grace period before loader shows
        loadingGrace: 1000 * 1,

        // Grace period before loader is always removed.
        errorGrace: 1000 * 10,

        // Timers
        loadingTimer: null,
        errorTimer: null,

        /**
         * Block the UI visually, to indicate slow loading.
         * Note: UI is blocked invisibly anyway
         */
        showLoadingBlock: function() {
            $.blockUI();
        },

        /**
         * Queues the Loading timer
         */
        queueLoadingBlocker: function() {
            // UI Loader detector
            // Note: BIND is not used because of PhantomJS
            // https://github.com/seiyria/bootstrap-slider/issues/344#issuecomment-93187123
            var _this = this;

            this.loadingTimer = setTimeout(function() {
                _this.showLoadingBlock()
            }, this.loadingGrace);

            this.errorTimer = setTimeout(function() {
                if (window.console) {
                    console.error(new Error('Page blocker timed out. The page took too long to load.'));
                }

                _this.unblock()
            }, this.errorGrace);
        },

        /**
         * Call this method to unblock the UI, indicating loading was successful.
         * - Disabled the Loading block, if hidden or queued
         * - Disabled the Error blocker
         * @public
         */
        unblock: function() {

            // Don't ever run twice
            if (this.loaded) {
                return;
            }

            // Indicate loaded, so blockers won't run
            this.loaded = true;

            // Clear timer, as it might not have fired.
            if (this.loadingTimer) {
                clearTimeout(this.loadingTimer);
            }

            if (this.errorTimer) {
                clearTimeout(this.errorTimer);
            }

            // Remove invisible blocker
            $('.elmo-busy-invisible').remove();

            // Unblock
            $.unblockUI();

            // Skip to summary before reading content for screen readers
            $("body #screenreader-summary").focus();
        },

        // Initialise timer for visual blocker
        init: function() {
            this.queueLoadingBlocker();
        }

    };

    // Apply to Window
    window._elmo_ui = blockUi;
    window._elmo_ui.init();

})(jQuery, window);